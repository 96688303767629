// Fonts
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-base: $font-family-sans-serif;

// Colors
$blue: #007bff;
$light: #eeeeee;

// Other
$custom-control-indicator-border-color: gray;
$custom-control-indicator-border-width: 2px;
$custom-control-indicator-checked-bg: transparent;
$custom-control-indicator-checked-border-color: $blue;
$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-size: 1.5rem;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#007bff'/%3e%3c/svg%3e");
$custom-select-bg-size: 25px 25px;
$custom-select-indicator: url("/images/dropdown.png");
$custom-select-indicator-padding: 1.25rem;
$enable-shadows: true;
$form-feedback-icon-invalid: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" class="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>');

@import "~bootstrap/scss/bootstrap";
